import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { AnnouncementListData } from '../../api/Announcement';
import Layout from '../../components/Layout';
import { BLACK, DEEPRED, WHITE } from '../../constants/colors';
import { ANNOUNCE_WIDTH, HEADER_HEIGHT, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { INTERNAL_URLS } from '../../constants/urls';
import AnnouncementServices from '../../services/AnnouncementServices';
import { getDesktopStyle } from '../../styles/layout';
import { getDateFormat, pxToRem } from '../../utils/common';

// 공고 목록
// 진입 후 api 요청하여 해당 값으로 랜더
const AnnouncementList = () => {
	const [data, setData] = useState<AnnouncementListData[]>();
	const [error, setError] = useState(false);

	const successCallback = useCallback((data: AnnouncementListData[]) => {
		setData(data);
	}, []);

	const errorCallback = useCallback(() => {
		setError(true);
	}, []);

	useEffect(() => {
		async function fetchData() {
			await AnnouncementServices.processGetAnnouncmentList({ successCallback, errorCallback });
		}
		fetchData();
	}, []);

	return (
		<Layout title="Petdoc - 공고" isNoticePage>
			<Wrapper>
				<ContentWrapper>
					<Subtitle>공고</Subtitle>
					{data ? (
						<AnnounceList>
							{data.map((item) => (
								<AnnounceItem key={item.noticeId}>
									<ItemLink to={`${INTERNAL_URLS.ANNOUNCE_DETAIL}?id=${item.noticeId}`}>
										<Time>{getDateFormat(item.noticeDate)}</Time>
										<Title>{item.title}</Title>
										<Content>{item.content}</Content>
									</ItemLink>
								</AnnounceItem>
							))}
						</AnnounceList>
					) : (
						<Skeleton>{error && <p>공고를 불러올 수 없습니다.</p>}</Skeleton>
					)}
				</ContentWrapper>
			</Wrapper>
		</Layout>
	);
};

export default AnnouncementList;

const Content = styled.p`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	word-break: break-all;
	font-weight: 300;
	font-size: ${pxToRem(13)};
	line-height: ${pxToRem(20)};

	${getDesktopStyle(css`
		width: ${pxToRem(778)};
		-webkit-line-clamp: 2;
		font-size: ${pxToRem(16)};
		line-height: ${pxToRem(23)};
	`)}
`;

const Title = styled.h2`
	font-weight: 400;
	font-size: ${pxToRem(16)};
	margin-bottom: ${pxToRem(10)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(28)};
		margin-bottom: ${pxToRem(10)};
	`)}
`;

const Time = styled.p`
	font-weight: 300;
	font-size: ${pxToRem(12)};
	margin-bottom: ${pxToRem(15)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(15)};
		margin-bottom: ${pxToRem(20)};
	`)}
`;

const ItemLink = styled(Link)`
	display: block;
	width: 100%;
	height: 100%;

	padding: ${pxToRem(29)} ${pxToRem(38)};

	${getDesktopStyle(css`
		padding: ${pxToRem(41)} ${pxToRem(73)} ${pxToRem(51)} ${pxToRem(73)};
	`)}
`;

const AnnounceItem = styled.li`
	width: 100%;
	border: 0.5px solid ${BLACK};
	border-radius: ${pxToRem(2)};

	${getDesktopStyle(css`
		border: 1px solid ${BLACK};
		&:hover {
			${Title},
			${Content} {
				color: ${DEEPRED};
			}
		}
	`)}
`;

const AnnounceList = styled.ul`
	margin-top: ${pxToRem(28)};

	li + li {
		margin-top: ${pxToRem(20)};
	}

	${getDesktopStyle(css`
		margin-top: ${pxToRem(55)};

		li + li {
			margin-top: ${pxToRem(32)};
		}
	`)}
`;

const Skeleton = styled.div`
	height: ${pxToRem(1000)};
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-size: ${pxToRem(15)};
	line-height: ${pxToRem(22)};

	${getDesktopStyle(css`
		height: ${pxToRem(500)};

		p {
			font-size: ${pxToRem(18)};
			line-height: ${pxToRem(26)};
		}
	`)}
`;

const ContentWrapper = styled.section`
	margin: 0 auto;
	font-size: ${pxToRem(18)};

	${getDesktopStyle(css`
		width: ${pxToRem(ANNOUNCE_WIDTH)};
	`)}
`;

const Subtitle = styled.p`
	font-weight: 400;
	font-size: ${pxToRem(15)};
	line-height: ${pxToRem(22)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(18)};
		line-height: ${pxToRem(26)};
	`)}
`;

const Wrapper = styled.main`
	background-color: ${WHITE};
	color: ${BLACK};
	padding: ${pxToRem(28)} ${pxToRem(24)} ${pxToRem(52)};

	${getDesktopStyle(css`
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
		padding: ${pxToRem(63 + HEADER_HEIGHT)} ${pxToRem(50)} ${pxToRem(63)} ${pxToRem(50)};
	`)}
`;
